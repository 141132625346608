/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer {
    padding: 50px 0 0px;
    overflow: hidden;
    background: $bg;
    // Responsive
    @media #{$tablet-device}{
        padding: 50px 0 0px;
    }
    @media #{$large-mobile}{
        padding: 50px 0 0px;
    }
}

.footer-widget {
}

.footer-logo{
    margin-bottom: 30px;
}

.footer-widget-title {
    font-size: 18px;
    color: $white;
    margin-bottom: 17px;
}

.footer-widget-content {
    font-size: 18px;
    font-weight: 500;
    color: $gray-100;

    & p {
        margin-bottom: 5px;
        color: $gray-100;
        font-family: $link-font;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & ul {
        margin-right: -20px;
        margin-bottom: -5px;
        padding-left: 0;

        list-style: none;

        & li {
            margin-bottom: 5px;
            padding-right: 20px;
            a{
                font-size: 17px;
                line-height: 24px;
                color: $white;
                // Responsive
                @media #{$desktop-device}{
                    font-size: 15px;
                }
                @media #{$extra-small-mobile}{
                    font-size: 15px;
                }

                &:hover{
                    opacity: 0.7;
                    text-decoration: underline !important;
                }

            }

        }
    }
}

.copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    border-top: 1px solid $gray-200;
    color: $white;
    margin-bottom: 0;
    & a {
        color: $white !important;
        &:hover{
            opacity: 0.7;
            text-decoration: underline !important;
        }
    }

}
