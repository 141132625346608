/* 04.05 CTA CSS */



.icon-up-down-animation{
    position: relative;
    z-index: 9;
    .shape-default{
        background: #e6f3fd;
        border-radius: 34%;
        i, svg {
            /*position: absolute;*/
            color: $primary;
            opacity: 0.75;
            margin: auto auto;
            width: 100%;
            height: 100%;
            padding: 20%;
        }
    }
    & .shape {
        position: absolute;
        display: inline-block;
        width: 82px;
        height: 82px;
        z-index: -1;
        display: block;
        box-shadow: 0 5px 25px rgba(0,0,0,0.2);
        transition:  0.2s ease-in-out ;
        z-index: 1000;

        &:hover  {
            box-shadow: 0 5px 5px rgba(0,0,0,0.4);
            i, svg {
                opacity: 1;
            }
        }

        span {
            width: 200%;
            margin-left: -60%;
            display: inline-block;
            color: $primary;
            font-size: 1.0rem;
            background: rgba(230, 243, 253, .75);
            border-radius: 3px;
            padding: 3px 12px;
        }
        img{
            box-shadow: 0 0 40px 0 rgba(51,51,51,0.1);
            border-radius: 35px;
        }

        &-1 {
            top: -250px;
            left: 300px;
            width: 90px;
            height: 90px;
            animation: up-down infinite 4s ease-in-out;
        }
        &-2 {
            top: -290px;
            right: 400px;
            animation: up-down infinite 5s ease-in-out;
        }
        &-3 {
            top: -165px;
            left: 15px;
            animation: up-down infinite 6s ease-in-out;

        }
        &-4 {
            top: -230px;
            right: 95px;
            width: 100px;
            height: 100px;
            animation: up-down infinite 3s ease-in-out;
            img{
                border-radius: 45px;
            }
        }
        &-5 {
            bottom: -170px;
            left: 80px;
            width: 115px;
            height: 115px;
            animation: up-down infinite 5s ease-in-out;
            img{
                border-radius: 45px;
            }
        }
        &-6 {
            bottom: -150px;
            right: 300px;
            animation: up-down infinite 6s ease-in-out;
        }
        &-7 {
            bottom: -130px;
            left: 370px;
            animation: up-down infinite 4s ease-in-out;
        }
        &-8 {
            bottom: -100px;
            right: 80px;
            animation: up-down infinite 5s ease-in-out;
        }
        &-9 {
            bottom: 0;
            right: 15px;
            animation: up-down infinite 6s ease-in-out;
        }
    }

    // Responsive
    @media #{$desktop-device}{

        & .shape {

            span {
                font-size: 1.0rem;
            }

            &-1 {
                top: -220px;
                left: 220px;
            }
            &-2 {
                top: -180px;
                right: 150px;
            }
            &-3 {
                top: -155px;
                left: 5px;

            }
            &-4 {
                top: -55px;
                right: 0px;
                width: 100px;
                height: 100px;
                img{
                    border-radius: 35px;
                }
            }
            &-5 {
                bottom: -110px;
                left: 20px;
                width: 100px;
                height: 100px;
                img{
                    border-radius: 35px;
                }
            }
            &-6 {
                bottom: -90px;
                right: 250px;
            }
            &-7 {
                bottom: -190px;
                left: 260px;
            }
            &-8 {
                bottom: -155px;
                right: 60px;
            }
            &-9 {
                bottom: 0;
                right: 15px;
            }
        }
    }
    @media #{$tablet-device}{
        & .shape {

            width: 60px;
            height: 60px;
            span {
                font-size: 0.9rem;
            }

            img{
                border-radius: 20px;
            }
            &-1 {
                top: -215px;
                left: 170px;
            }
            &-2 {
                top: -235px;
                right: 150px;
            }
            &-3 {
                top: -180px;
                left: 15px;
            }
            &-4 {
                top: -200px;
                right: 0px;
            }
            &-5 {
                bottom: -60;
                left: 10px;

            }
            &-6 {
                bottom: -70px;
                right: 150px;
            }
            &-7 {
                bottom: -130px;
                left: 190px;
            }
            &-8 {
                bottom: -130px;
                right: 20px;
            }
            &-9 {
                bottom: 110px;
                right: 15px;
            }
        }
    }

    @media #{$large-mobile}{
        & .shape {

            width: 45px;
            height: 45px;
            span {
                font-size: 0.90rem;
                width: 260%;
                margin-left: -80%;
            }

            img{
                border-radius: 15px;
            }
            &-1 {
                top: -310px;
                left: 10px;
            }
            &-2 {
                top: -150px;
                right: 150px;
            }
            &-3 {
                top: -280px;
                left: 150px;

            }
            &-4 {
                top: -160px;
                right: 0px;
            }
            &-5 {
                bottom: -190px;
                left: 10px;
            }
            &-6 {
                bottom: -70px;
                right: 20px;
            }
            &-7 {
                bottom: -50px;
                left: 30px;
            }
            &-8 {
                bottom: -210px;
                right: 0px;
            }

        }
    }

}

/* Up Down Animation */
.up-down {
    animation: up-down infinite 2s ease-in-out
}
@keyframes up-down {
    50% { transform: translateY(30px) }
}
