/*--------------------- Hotstops ---------------------*/

.hotspots {

  width: 100%;
  height: 100%;
  position: relative;

  .section-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .dot {
    position: absolute;
    width: 36px;
    height: 36px;
    background-size: 1290px;
    z-index: 100;
    display: block;
    transition: all 0.3s;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.5);
    color: #fff;
    border: 3px solid $primary;
    background-color: rgba(255, 255, 255, 0.6);
    background-repeat: no-repeat;

    span {
      opacity: 0;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.7);
    }

    .dot-bg {

      img {
        position: absolute;
        width: 1290px;
        max-width: inherit;
      }
    }
  }

  @media #{$large-mobile}{
    .dot-sm {
      width: 24px;
      height: 24px;
    }
  }

  .active {
    width: 280px;
    height: 280px;
    margin-top: -120px;
    margin-left: -120px;
    border: none;
  }

  .blur-background {
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    -webkit-transition: -webkit-filter 0.5s;
    transition: -webkit-filter 0.5s;
    transition: filter 0.5s;
  }
}

.blured .blur-background {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.nav-tabs .collapsed.btn {
  background: $DGUV_blue;
}

.section-demenz {
  .hotspots {
    .dot {
      border: 3px solid $DGUV_green;
    }
  }
}

.section-barrierearm {
  .hotspots {
    .dot {
      border: 3px solid $DGUV_orange;
    }
  }
}

.box-shadow {
  box-shadow: 0px 10px 30px #999;
}

.arrows {
  position: absolute;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  transition: all 180ms;
  z-index: 99;
}
.arrows:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 7px solid rgba(0, 73, 148, 0.7);
  border-bottom: 7px solid rgba(0, 73, 148, 0.7);
  transform: translate(0px, 30px) rotate(-45deg);
  animation: arrows 3s linear infinite;
}
.arrows:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 7px solid rgba(0, 73, 148, 0.8);
  border-bottom: 7px solid rgba(0, 73, 148, 0.8);
  transform: translate(0px, 30px) rotate(-45deg);
  animation: arrows 3s linear infinite -1.5s;
}
@keyframes arrows {
  0% {
    border-left: 7px solid rgba(0, 73, 148, 0);
    border-bottom: 7px solid rgba(0, 73, 148, 0);
    transform: translate(0px, 30px) rotate(135deg);
  }
  10%, 90% {
    border-left: 7px solid rgba(222, 222, 222, 0.7);
    border-bottom: 7px solid rgba(222, 222, 222, 0.7);
  }
  50% {
    border-left: 7px solid white;
    border-bottom: 7px solid white;
    transform: translate(0px, 0px) rotate(135deg);
  }
  100% {
    border-left: 7px solid rgba(0, 73, 148, 0);
    border-bottom: 7px solid rgba(0, 73, 148, 0);
    transform: translate(0px, -30px) rotate(135deg);
  }
}

@media #{$large-mobile}{
  .arrows {
    width: 20px;
    height: 20px;
  }
  .arrows:before {
    border-left: 3px solid rgba(0, 73, 148, 0.7);
    border-bottom: 3px solid rgba(0, 73, 148, 0.7);
  }
  .arrows:before {
    border-left: 3px solid rgba(0, 73, 148, 0.7);
    border-bottom: 3px solid rgba(0, 73, 148, 0.7);
  }
  @keyframes arrows {
    0% {
      border-left: 3px solid rgba(0, 73, 148, 0);
      border-bottom: 3px solid rgba(0, 73, 148, 0);
      transform: translate(0px, 10px) rotate(135deg);
    }
    10%, 90% {
      border-left: 3px solid rgba(222, 222, 222, 0.7);
      border-bottom: 3px solid rgba(222, 222, 222, 0.7);
    }
    50% {
      border-left: 3px solid rgba(250, 250, 250, 0.5);
      border-bottom: 3px solid rgba(250, 250, 250, 0.5);
      transform: translate(0px, 0px) rotate(135deg);
    }
    100% {
      border-left: 3px solid rgba(0, 73, 148, 0);
      border-bottom: 3px solid rgba(0, 73, 148, 0);
      transform: translate(0px, -10px) rotate(135deg);
    }
  }
}

/*--------------------- HEADER COLORS ---------------------*/

.section-allgemein .page {
  background: linear-gradient(5deg, $DGUV_green, $DGUV_blue 60%) !important;
}

.section-demenz .page {

  background: linear-gradient(5deg, $DGUV_blue, $DGUV_green 60%) !important;
}

.section-barrierearm .page {
  background: linear-gradient(5deg, #ffd99f, $DGUV_orange 60%) !important;
}

/*--------------------- TABLES ---------------------*/

table {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #164194 !important;
}

table tbody {
  vertical-align: top;
}

table tr:nth-child(2n+1) {
  background: #f3f7ff;
}

table tr:nth-child(2n) {
  background: #fff;
}

table tr th {
  background: #164194;
  color: #fff;
  padding: 4px 10px;
  line-height: 1.5rem;
}

table tr td {
  padding: 4px 10px;
  line-height: 1.5rem;
}

/*--------------------- ANIMATIONS ---------------------*/

.sectionImg {
  position: relative;
}

.sectionImg img {
  border-radius: 5px
}

.sectionImg img:first-child {
  position: absolute;
  left: 0;
  top: 0;
}

.fadeOut {
  animation-delay: .7s;
  animation-name: fadeOut;
  animation-duration: 2000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.thumbsUp {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  padding: 20px;
}

.thumbsUp i, .thumbsUp svg {
  animation-delay: .8s;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: rotate(25deg);
}

@keyframes spin {
  from {
    transform: rotate(25deg);
  }
  to {
    transform: rotate(180deg);
    color: $green;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*--------------------- JOIN-OUR-TEAM ---------------------*/

.term {
  padding: 180px 0 150px;

  .title-bg-left {
    top: 66px;
  }
}

.term > div {
  margin-bottom: 30px;
}

.term-item {
  display: block;
  position: relative;
  padding: 32px 30px;
  background: #eff4ee;
  border-radius: 5px;
  background-size: cover;
  transition: .6s ease;
  text-shadow: 2px 2px 2px #333;

  .bg-gradient {
    opacity: 0.65
  }

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    background-position-y: 50% !important;

    .bg-gradient {
      opacity: 0.05;
    }
  }

  h4 {
    margin-bottom: 17px;
    color: #fff;
    text-decoration: none;
    position: relative;
    z-index: 100;
    min-height: 64px;
  }
}

/*--------------------- GRADIENT ---------------------*/

.bg-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  opacity: .7;
}

.bg-gradient-default {
  background: $overlay-blue-green !important;
}

.jumbotron-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cocoen-drag::before {
  height: 80px;
  left: 50%;
  margin-left: -18px;
  margin-top: -18px;
  top: 48%;
  width: 36px;
  background: $info;
  opacity: 0.75;
  border-radius: 2px;

  &:hover {
    opacity: 1.00;
  }
}

/** MENU Badges **/

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.badge-sup {
  top: -1em;
  position: relative;
  font-size: 66%;
  vertical-align: baseline;
}

