#cookies-bar
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  background-color: white
  padding: 30px
  z-index: 999
  border-top: 1px solid #164194

  .buttons > a
    height: 40px !important

  .buttons > .btn-accept-all:hover
    color: white !important
