/* 05.04 Header Logo CSS */

.header-title {
    & a {
        font-size: 22px;
        color: $white;
        line-height: 25px;
        display: block;
        margin-top:4px;
        strong {
            text-transform: uppercase;
            display: block;
            font-size: 130%;
        }
    }

    @media #{$large-mobile} {
        & a {
            font-size: 24px;
        }
    }

    @media #{$small-mobile} {
        & a {
            font-size: 24px;
            line-height: 26px;
        }
    }

    @media #{$extra-small-mobile} {
        & a {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

.header-light {
    & .header-title {
        & a {
            color: $black;
        }
    }
}
