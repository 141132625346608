.goverlay {
  background: rgb(0 0 0 / 50%);
}

.glightbox-clean .gclose {
  width: 50px;
  height: 50px;
}

.glightbox-clean .gclose svg {
  width: 30px;
  height: auto;
}

#glightbox-body .gprev,
#glightbox-body .gnext {
  display: none;
}