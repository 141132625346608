/* 04.01 Accordion CSS */

.faq-section{
    padding-bottom: 120px;
    // Responsive
    @media #{$tablet-device}{
        padding-bottom: 280px;
    }
    @media #{$large-mobile}{
        padding-bottom: 260px;
    }
}


.agency-accordion{
    .card{
        border: 1px solid #e7e7e7;
        margin-bottom: 30px;
        -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        &:last-child{
            margin-bottom: 0;
        }
    }
    .card-header{
        position: relative;
        background: transparent;
        padding: 0;
        border: 1px solid #e7e7e7;
        border-bottom: 0 none;

        h5{
            a{
                &.acc-btn{
                    color: $heading-color;
                    display: block;
                    background: $white;
                    border: 1px solid #eee;
                    padding: 20px 26px;
                    margin: -1px -1px 0;
                    position: relative;
                    border-radius: 5px;

                    &::before{
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        content: "\f107";
                        right: 16px;
                        font-size: 20px;
                        color: $heading-color;
                    }

                    &[aria-expanded="true"]{
                        background: $white;
                        color: $heading-color;
                        &::before{
                            content: "\f106";
                            color: $heading-color;
                        }
                    }


                }
                &.border-0{
                    border: 0;
                    border-radius: 5px;
                }
            }
        }
    }
    .card-body{
        padding: 10px 25px 39px;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.74;
        font-size: 15px;
        font-family: $body-font;
        border: 0 none;
        box-shadow: 0 20px 40px rgba(0,0,0,.07);
        -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        border-radius: 0 0 5px 5px;
    }
}

.faq-image-area{
    position: relative;
    z-index: 9;
    height: 100%;
    margin-right: 0 !important;

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            top: auto;
            bottom: 145px !important;
            right: -128px !important;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$laptop-device}{
        & .shape {

            &-1 {
                top: auto;
                bottom: 145px !important;
                right: 0px !important;
            }
        }
    }
    @media #{$desktop-device}{
        & .shape {

            &-1 {
                top: auto;
                bottom: 145px !important;
                right: 0px !important;
            }
        }
    }
    @media #{$tablet-device}{
        & .shape {

            &-1 {
                top: auto;
                bottom: 145px !important;
                right: 0px !important;
            }
        }
    }
    @media #{$large-mobile}{
        & .shape {

            &-1 {
                top: auto;
                bottom: 145px !important;
                right: 0px !important;
            }
        }
    }
}
