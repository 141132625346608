/* 04.03 Breadcrumb CSS */

.breadcrumb {
  padding: 40px 0;

  background-color: transparent;

  // Responsive
  @media #{$large-mobile}{
    padding: 25px 0;
  }

  & li {
    font-size: 16px;
    line-height: 1.5;

    display: flex;

    color: $white;

    & + li {
      &::before {
        margin: 0 15px;
        font-size: 16px;
        content: "\f105";
        font-family: $fontAwesome5Pro;

        color: $white;
        margin-top: 2px;
      }
    }

    & a {
      position: relative;

      display: block;

      color: $white;

      &::before {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 0;
        height: 1px;

        content: "";
        transition: $transition;

        background-color: $white;
      }

      &:hover {
        color: $white;

        &::before {
          left: 0;

          width: 100%;
        }
      }
    }
  }
}

.breadcrumb.br-simple {
  padding: 15px 1px;

  & li.current {
    color: $DGUV_blue;
  }

  & li {
    color: $DGUV_gray;

    &::before {
      color: $DGUV_gray;
    }

    & a {
      color: $DGUV_gray ;

      &:hover {
        color: $DGUV_blue;
      }
    }
  }
}
